import React from 'react';
import { Link } from 'gatsby';
import * as styles from './Slider.module.scss';

class Slider extends React.Component {
  state = {
    currentSlide: sliderItems[0],
    previousSlide: null,
    hasPreviousSlideStartedFading: false,
  };

  render() {
    const {
      currentSlide,
      previousSlide,
      hasPreviousSlideStartedFading,
    } = this.state;

    return (
      <div className={styles.slider}>
        <div className={`${styles.sliderImg}`}>
          {currentSlide.content}

          {previousSlide && (
            <div
              className={`${styles.previousSlide} ${
                hasPreviousSlideStartedFading ? styles.fading : ''
              }`}
            >
              <div className={previousSlide.class} />
            </div>
          )}

          <div className={styles.currentImage}>
            <div className={currentSlide.class} />
          </div>
        </div>
        <div className={styles.sliderControls}>
          {sliderItems.map((sliderItem, index) => (
            <button
              key={index}
              onClick={() => {
                this.setSlide(sliderItem);
                this.stopTimer();
                this.startTimer();
              }}
              className={`${styles.sliderControl} ${
                sliderItem === currentSlide ? styles.active : ''
              }`}
            />
          ))}
        </div>
      </div>
    );
  }
  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  stopTimer() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  startTimer() {
    if (!this.intervalId) {
      this.intervalId = setInterval(() => {
        const indexOfCurrentSlide = sliderItems.indexOf(
          this.state.currentSlide
        );

        const nextSlideIndex =
          indexOfCurrentSlide === sliderItems.length - 1
            ? 0
            : indexOfCurrentSlide + 1;

        this.setSlide(sliderItems[nextSlideIndex]);
      }, 7100);
    }
  }

  setSlide(newSlide) {
    this.setState(
      {
        currentSlide: newSlide,
        previousSlide: this.state.currentSlide,
        hasPreviousSlideStartedFading: false,
      },
      () => {
        setTimeout(() => {
          this.setState(
            {
              hasPreviousSlideStartedFading: true,
            },
            () => {
              setTimeout(() => {
                this.setState({
                  previousSlide: null,
                  hasPreviousSlideStartedFading: false,
                });
              }, 300);
            }
          );
        }, 90);
      }
    );
  }
}

export default Slider;

const Slide1Content = () => (
  <div className={styles.sliderContent}>
    <p className={styles.title}>Impression Grand Format</p>
    <p>
      Spécialisé dans le Grand Format, notre atelier de fabrication imprime vos
      <br />
      visuels sur des supports souples ou rigides allant jusqu'à{' '}
      <span className={styles.bigText}>3m20</span> de large !
    </p>
    <div>
      <Link className={`${styles.btn} ${styles.btnPrimary}`} to="/grand-format">
        En savoir plus
      </Link>
    </div>
  </div>
);

const Slide2Content = () => (
  <div className={styles.sliderContent}>
    <p className={styles.title}>Impression tous supports</p>
    <p>
      Pour tous vos projets d'impression, nous vous accompagnons avec le souci
      constant d'optimiser votre budget, <br />
      de vous proposer des produits de qualité et de vous offrir un
      accompagnement sur mesure.
    </p>
    <div>
      <Link
        className={`${styles.btn} ${styles.btnPrimary} `}
        to="/impression-tous-supports"
      >
        En savoir plus sur
      </Link>
    </div>
  </div>
);

const Slide3Content = () => (
  <div className={styles.sliderContent}>
    <p className={styles.title}>Notre force, la réactivité</p>
    <p>
      Obtenez une réponse à vos devis entre 1h et 6h maximum !* <br />
      *hors produits complexes
    </p>
    <div>
      <Link
        className={`${styles.btn} ${styles.btnPrimary} `}
        to="/nos-engagements"
      >
        En savoir plus
      </Link>
    </div>
  </div>
);

const Slide4Content = () => (
  <div className={styles.sliderContent}>
    <p className={styles.title}>
      Bienvenue dans notre
      <br /> <span className={styles.snickles}>univers Printastic !</span>
    </p>
  </div>
);

const sliderItems = [
  { content: <Slide4Content />, class: styles.Img4 },
  { content: <Slide1Content />, class: styles.Img1 },
  { content: <Slide2Content />, class: styles.Img2 },
  { content: <Slide3Content />, class: styles.Img3 },
];
